<script lang="ts" setup>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { AisConfigure, AisInstantSearch } from "vue-instantsearch/vue3/es";
import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex as singleIndexMapping } from "instantsearch.js/es/lib/stateMappings";

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  contentTypeId: number;
  beforeContent?: string;
  afterContent?: string;
}>();

const id = ref((Math.random() + 1).toString(36).substring(6));
const config = useRuntimeConfig();
const host = config.public.meilisearchHost;
const searchApiKey = config.public.meilisearchKey;
const options = { finitePagination: true, primaryKey: "id" };
const sortingRef = ref("updated_at:desc");
const routingRef = ref({
  router: history({
    cleanUrlOnDispose: true,
  }),
  stateMapping: singleIndexMapping(
    `${config.public.meilisearchIndexContentElements}:${sortingRef.value}`,
  ),
});
const { searchClient } = instantMeiliSearch(host, searchApiKey, options);
const { data: filterableProperties } = await useApiFetch(
  `content-type/${props.contentTypeId}/filterable-properties`,
);
// console.log(filterableProperties);
</script>

<template>
  <AisInstantSearch
    :id="`content-${id}`"
    class="content-element-list md:p-12"
    :routing="routingRef"
    :index-name="`${config.public.meilisearchIndexContentElements}:${sortingRef}`"
    :search-client="searchClient"
    :future="{
      preserveSharedStateOnUnmount: false,
      persistHierarchicalRootCount: false,
    }"
  >
    <slot name="default" />
    <AisConfigure
      :filters="`content_type_id = ${contentTypeId}`"
      :hits-per-page.camel="60"
    />

    <!-- Active filters -->
    <CmsPageContentElementListCurrentRefinements class="md:col-span-4" />

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-3 xl:col-span-2">
        <!-- Filters -->
        <CmsPageContentElementListFilters
          v-if="filterableProperties"
          :filters="filterableProperties"
        />
      </div>
      <div class="col-span-12 md:col-span-9 xl:col-span-10">
        <!-- Search results / pagination -->
        <CmsPageContentElementListSearchResults
          class="md:col-span-3"
          :before-content="beforeContent"
          :after-content="afterContent"
          :content-element-id="`content-${id}`"
        />
      </div>
    </div>
  </AisInstantSearch>
</template>
