<script lang="ts" setup>
import { AisHits } from "vue-instantsearch/vue3/es";
import { useContentElementListStore } from "~/stores/contentElementList";

// -----------------------
// props & emits
// -----------------------
const props = defineProps<{
  beforeContent?: string | null;
  afterContent?: string | null;
  contentElementId: string;
}>();

// -----------------------
// composables
// -----------------------
const contentElementListStore = useContentElementListStore();
const { getAssetUrl } = useHelpers();

// -----------------------
// helper methods
// -----------------------
/*
const getPublishDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
*/

const formatDistance = (distanceInMeters: number) => {
  // Convert distance to kilometers and round to 1 decimal place
  const distanceInKilometers = (distanceInMeters / 1000).toFixed(1);
  // Return formatted string with " km" appended
  return distanceInKilometers.replace(".", ",") + " km";
};

const toggleFilterLayerVisibility = () => {
  contentElementListStore.filtersLayerVisible =
    !contentElementListStore.filtersLayerVisible;
};

const { isRealContent } = useHelpers();

const getDifficulty = (val: any) => {
  const parts = val.split("|");
  if (parts.length === 2) return parts[1];
  return val;
};

const isDeclarationOfConformity = (items: Record<string, any>[]) => {
  if (items.length > 0) {
    // FIXME: add SK and RO names here
    return ["Megfelelőségi nyilatkozat"].includes(items[0].content_type_name);
  }

  return false;
};

const itemsHavingPdfUrl = (items: Record<string, any>[]) => {
  return items.filter(
    (item) =>
      typeof item?.contentelement_megfelelosegi_nyilatkozat_pdf_property
        ?.url === "string",
  );
};
</script>

<template>
  <div class="bg-brand-surface md:bg-white pb-5">
    <div
      class="flex items-center md:hidden cursor-pointer p-12"
      @click="toggleFilterLayerVisibility"
    >
      <i class="m-icon-filter text-2xl mr-[10px]"></i> {{ $t("filter") }}
    </div>

    <AisHits>
      <template #default="{ items }">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="isRealContent(props.beforeContent)"
          class="mb-5"
          v-html="props.beforeContent"
        ></div>
        <!--eslint-enable-->
        <div v-if="isDeclarationOfConformity(items)">
          <a
            v-for="item in itemsHavingPdfUrl(items)"
            :key="item.id"
            class="block !text-black mb-[10px]"
            :title="item.name"
            target="_blank"
            :href="
              getAssetUrl(
                item.contentelement_megfelelosegi_nyilatkozat_pdf_property.url,
              )
            "
          >
            {{ item.name }}
          </a>
        </div>
        <div
          v-else
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-16 bg-brand-surface p-[27px] md:p-[12px] md:rounded-16"
        >
          <div
            v-for="item in items"
            :key="item.id"
            class="rounded-16 bg-white p-16"
          >
            <article
              v-if="
                ['Tudástár', 'Tipy, Rady', 'Sfaturi', 'Túratippek'].includes(
                  item.content_type_name,
                )
              "
              class="flex flex-col h-full"
            >
              <!-- Thumbnail -->
              <div
                v-if="
                  ['Tudástár', 'Tipy, Rady', 'Sfaturi'].includes(
                    item.content_type_name,
                  )
                "
                class="w-full flex justify-center items-center aspect-content-element-thumbnail"
              >
                <a
                  v-if="item.contentelement_fejleckep_property"
                  :href="item.relative_url"
                >
                  <NuxtImg
                    :src="
                      getAssetUrl(item.contentelement_fejleckep_property.url)
                    "
                    width="500"
                    class="rounded-16"
                    format="webp"
                    loading="lazy"
                  />
                </a>
              </div>

              <div
                v-if="item.content_type_name === 'Túratippek'"
                class="relative aspect-turatipp-thumbnail"
              >
                <a
                  v-if="item.contentelement_turatipp_thumbnail_property"
                  :href="item.relative_url"
                >
                  <NuxtImg
                    :src="
                      getAssetUrl(
                        item.contentelement_turatipp_thumbnail_property,
                      )
                    "
                    class="rounded-16 absolute inset-0 w-full h-full object-cover"
                    format="webp"
                    loading="lazy"
                    width="500"
                  />
                </a>
              </div>

              <div>
                <!-- Categories -->
                <span
                  v-for="category in item.contentelement_kategoria_property"
                  :key="category"
                  class="bg-brand-secondary inline-block rounded-5 px-[12px] py-[5px] text-12 mr-16 mb-16 mt-16"
                  >{{ category }}</span
                >

                <!-- Location -->
                <span
                  v-if="
                    item.content_type_name === 'Túratippek' &&
                    item.contentelement_turatipp_location_property
                  "
                  class="bg-brand-secondary inline-block rounded-5 px-[12px] py-[5px] text-12 mr-16 mb-16 mt-16"
                  >{{ item.contentelement_turatipp_location_property }}</span
                >

                <!-- Is round trip -->
                <span
                  v-if="item.content_type_name === 'Túratippek'"
                  class="bg-brand-secondary inline-block rounded-5 px-[12px] py-[5px] text-12 mr-16 mb-16 mt-16"
                  >{{
                    item.contentelement_turatipp_is_roundtrip_property
                  }}</span
                >
              </div>

              <!-- Published -->
              <!--
              <div
                v-if="
                  ['Tudástár', 'Tipy, Rady', 'Sfaturi'].includes(
                    item.content_type_name,
                  )
                "
                class="font-semibold text-14 text--brand-primary"
              >
                {{ getPublishDate(item.updated_at) }}
              </div>
              -->

              <section class="flex-grow flex flex-col justify-between">
                <div>
                  <!-- Title -->
                  <div class="font-bold text-xl font-lora !mt-16">
                    <a :href="item.relative_url" class="!text-black">{{
                      item.name
                    }}</a>
                  </div>

                  <!-- Subtitle / excerpt -->
                  <!--
                  <p
                    v-if="item.contentelement_alcim_property"
                    class="mt-16 text-16 leading-[24px] text-brand-body flex-grow"
                  >
                    {{ item.contentelement_alcim_property }}
                  </p>
                  -->

                  <template v-if="item.content_type_name === 'Túratippek'">
                    <div class="text-base mt-5">
                      <!-- Distance -->
                      <div
                        v-if="item.contentelement_turatipp_distance_property"
                      >
                        <b>{{ $t("distance") }}:</b>
                        {{
                          formatDistance(
                            item.contentelement_turatipp_distance_property,
                          )
                        }}
                      </div>

                      <!-- Elevation gain -->
                      <div
                        v-if="
                          item.contentelement_turatipp_elevation_gain_property
                        "
                      >
                        <b>{{ $t("elevation_gain") }}:</b>
                        {{
                          item.contentelement_turatipp_elevation_gain_property
                        }}
                        m
                      </div>

                      <!-- Difficulty -->
                      <div v-if="item.contentelement_difficulty_property">
                        <b>{{ $t("difficulty") }}:</b>
                        {{
                          getDifficulty(item.contentelement_difficulty_property)
                        }}
                      </div>
                    </div>
                  </template>
                </div>
                <!-- Read more text -->
                <a
                  :href="item.relative_url"
                  class="flex items-center self-end text-18 font-semibold mt-30"
                >
                  {{ $t("content_element.read_more") }}
                  <i class="m-icon-mini_arrow text-10 ml-[5px]"></i>
                </a>
              </section>
            </article>
          </div>
        </div>
      </template>
    </AisHits>
    <CmsPageContentElementListPagination
      class="mt-16"
      :content-element-id="contentElementId"
    />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="isRealContent(props.afterContent)"
      class="mt-5"
      v-html="props.afterContent"
    ></div>
    <!--eslint-enable-->
  </div>
</template>
